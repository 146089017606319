import * as React from "react";
import * as Polished from "polished";
import styled, { css } from "styled-components";

import * as Style from "~/style";

export type Value = number | string;

export function invertHex(hex: string) {
	if (hex.indexOf("#") === 0) {
		hex = hex.slice(1);
	}
	// convert 3-digit hex to 6-digits.
	if (hex.length === 3) {
		hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
	}
	if (hex.length !== 6) {
		throw new Error("Invalid HEX color.");
	}
	// invert color components
	var r = (255 - parseInt(hex.slice(0, 2), 16)).toString(16),
		g = (255 - parseInt(hex.slice(2, 4), 16)).toString(16),
		b = (255 - parseInt(hex.slice(4, 6), 16)).toString(16);
	// pad each with zeros and return
	return "#" + padZero(r) + padZero(g) + padZero(b);
}

function padZero(str: string, len: number = 2) {
	var zeros = new Array(len).join("0");
	return (zeros + str).slice(-len);
}

export function px(n: number) {
	return `${n}px`;
}

export function vh(n: number) {
	return `${n}px`;
}

export function ms(n: number) {
	return `${n}ms`;
}

export function size(height: Value = "auto", width: Value = "auto") {
	height = suffix(height);
	width = suffix(width);
	return css`
		height: ${height};
		width: ${width};
	`;
}

export function minSize(height: Value = "auto", width: Value = "auto") {
	height = suffix(height);
	width = suffix(width);
	return css`
		min-height: ${height};
		min-width: ${width};
	`;
}

export function maxSize(height: Value = "auto", width: Value = "auto") {
	height = suffix(height);
	width = suffix(width);
	return css`
		max-height: ${height};
		max-width: ${width};
	`;
}

export function position(
	position: string,
	top: Value,
	right: Value,
	bottom: Value,
	left: Value
) {
	top = suffix(top);
	right = suffix(right);
	bottom = suffix(bottom);
	left = suffix(left);

	return css`
		position: ${position};
		top: ${top};
		right: ${right};
		bottom: ${bottom};
		left: ${left};
	`;
}

export function suffix(value: string | number) {
	if (typeof value === "number") {
		value = `${value}px`;
	}
	return value;
}

//

export function square(length: Value = "100%") {
	return size(length, length);
}

export function circle() {
	return css`
		border-radius: 50%;
	`;
}

export function fill() {
	return size("100%", "100%");
}

export function flex(
	flexDirection: string = "row",
	justifyContent: string = "center",
	alignItems: string = "center"
) {
	return css`
		display: flex;
		flex-direction: ${flexDirection};
		justify-content: ${justifyContent};
		align-items: ${alignItems};
	`;
}

export function absolute(
	top: Value = "unset",
	right: Value = "unset",
	bottom: Value = "unset",
	left: Value = "unset"
) {
	return position("absolute", top, right, bottom, left);
}

export function sticky(
	top: Value = 0,
	right: Value = 0,
	bottom: Value = 0,
	left: Value = 0
) {
	return position("sticky", top, right, bottom, left);
}

export function fixed(
	top: Value = 0,
	right: Value = 0,
	bottom: Value = 0,
	left: Value = 0
) {
	return position("fixed", top, right, bottom, left);
}

export function font(
	family: string = "inherit",
	weight: string = "inherit",
	size: string = "inherit",
	style: string = "inherit"
) {
	return css`
		font-family: ${family};
		font-weight: ${weight};
		font-size: ${size};
		font-style: ${style};
	`;
}

const overrides = Object.freeze({
	button: {
		border: "none !important",
		outline: "none !important",
		boxShadow: "none !important",
		borderRadius: "0 !important",
	},
	input: {
		border: "none",
		outline: "none",
	},
	ul: {
		padding: 0,
	},
});

export function override(element: keyof typeof overrides) {
	return css(overrides[element]);
}

export function shadow(color: string) {
	return css`
		box-shadow: 10px 0px 58px -6px ${color};
	`;
}

export function transition(
	property: string = "all",
	duration: number = Style.Constants.animation.duration.fast,
	delay: number = 0,
	timingFunction: string = Polished.timingFunctions("easeInOutCubic")
) {
	return css`
		transition: ${property} ${ms(duration)} ${ms(delay)} ${timingFunction};
	`;
}

export function grid(
	columnRepeat: number = 1,
	columnSize: string = "auto",
	gap: number = 0,
	justifyItems: string = "auto",
	alignItems: string = "auto"
) {
	return css`
		display: grid;
		grid-template-columns: repeat(${columnRepeat}, ${columnSize});
		grid-gap: ${gap}px;
		justify-items: ${justifyItems};
		align-items: ${alignItems};
	`;
}

export function gridded(
	height: Value = "1fr",
	width: Value = "100px",
	gap: Value = "10px",
	maxWidth: Value = "1fr"
) {
	return css`
		display: grid;
		grid-template-columns: repeat(
			auto-fit,
			minmax(${suffix(width)}, ${suffix(maxWidth)})
		);
		grid-auto-rows: ${suffix(height)};
		grid-gap: ${suffix(gap)};
	`;
}

export function debug(color: string, type: string, size: number) {
	return css`
		border: ${color} ${type} ${size}px;
	`;
}

export function hideScrollbar() {
	return css`
		-ms-overflow-style: none;

		::-webkit-scrollbar {
			display: none;
		}
	`;
}

export function center() {
	return css`
		top: 50%;
		left: 50%;
		transform: translate3d(-50%, -50%, 0);
	`;
}

export function gradient(degree: number = 0, color1: string, color2: string) {
	return css`
		background-image: linear-gradient(${degree}deg, ${color1}, ${color2});
	`;
}

export function responsivePadding(
	device: string,
	topPadding: string = "0px",
	bottomPadding: string = "0px"
) {
	let sidePadding: number;
	if (device === "ultrawide" || device === "desktop") {
		sidePadding = Style.Constants.layout.desktop.padding;
	} else if (device === "tablet") {
		sidePadding = Style.Constants.layout.tablet.padding;
	} else {
		sidePadding = Style.Constants.layout.mobile.padding;
	}

	return `${topPadding} ${sidePadding}px ${bottomPadding};`;
}

export function responsiveOutput(
	device: string,
	desktop: string,
	tablet: string,
	mobile: string
) {
	if (device === "ultrawide" || device === "desktop") {
		return desktop;
	} else if (device === "tablet") {
		return tablet;
	} else {
		return mobile;
	}
}
