import * as React from "react";

import * as Helpers from "~/context/helpers";
import * as TS from "~/context/ts";
import * as Domains from "~/context/lib/domains";

import * as Style from "~/style";

export type Domain = ReturnType<typeof useThemeContext>["theme"];
export type Provider = typeof useThemeContext.Provider & React.ReactWithChildren;

export type Device = typeof devices[keyof typeof devices];
export type Mode = typeof modes[keyof typeof modes];

export type State = {
	device: Device;
	mode: Mode;
};

export const devices = {
	mobile: "mobile" as "mobile",
	tablet: "tablet" as "tablet",
	desktop: "desktop" as "desktop",
	ultrawide: "ultrawide" as "ultrawide"
};

export const modes = {
	dark: "dark" as "dark",
	light: "light" as "light",
}

export const initialState: State = Object.freeze({
	device: devices.desktop,
	mode: modes.dark
});

export const useThemeContext = Helpers.createUseContext(
	(props: Partial<State> = initialState) => {
		const [theme, _setTheme] = React.useState({
			...initialState,
			...props
		});

		// --- Getters --- //

		function getDevice(): Device {
			return theme.device;
		}

		function getDeviceIsMobile(): boolean {
			return Boolean(theme.device === devices.mobile);
		}

		function getDeviceIsTablet(): boolean {
			return Boolean(theme.device === devices.tablet);
		}

		function getDeviceIsDesktop(): boolean {
			return Boolean(theme.device === devices.desktop);
		}

		function getDeviceIsUltrawide(): boolean {
			return Boolean(theme.device === devices.ultrawide);
		}

		function getDeviceIsDesktopOrBigger(): boolean {
			return Boolean(
				theme.device === devices.desktop || theme.device === devices.ultrawide
			);
		}

		// --- Setters --- //

		function setTheme(state: Partial<State>): void {
			return _setTheme({ ...theme, ...state });
		}

		function setThemeDevice(device: Device) {
			setTheme({ device });
		}

		const setThemeDeviceMobile = () => setThemeDevice(devices.mobile);
		const setThemeDeviceDesktop = () => setThemeDevice(devices.desktop);
		const setThemeDeviceTablet = () => setThemeDevice(devices.tablet);
		const setThemeDeviceUltrawide = () => setThemeDevice(devices.ultrawide);

		// --- Togglers --- //
		function toggleMode(): void {
			return setTheme({ mode: theme.mode === "light" ? "dark" : "light" });
		}

		// --- Effects --- //

		function useEffectSetThemeDevice(
			windowSizeCurrent: Domains.WindowSize.EventState
		): void {
			const { width } = windowSizeCurrent;
			const entries = Object.entries(Style.Constants.breakpoints);

			const device = entries.reduce((acc, entry) => {
				const [device, values] = entry;
				const { min, max } = values;

				if (width > min && width < max) {
					acc = device as Device;
				}

				return acc;
			}, "" as Device);

			setThemeDevice(device);
		}

		// --- Exports --- //

		const getters = {
			getDevice,
			getDeviceIsMobile,
			getDeviceIsTablet,
			getDeviceIsDesktop,
			getDeviceIsUltrawide,
			getDeviceIsDesktopOrBigger
		};

		const setters = {
			setTheme,
			setThemeDevice,
			setThemeDeviceMobile,
			setThemeDeviceTablet,
			setThemeDeviceDesktop,
			setThemeDeviceUltrawide
		};

		const togglers = {
			toggleMode
		};

		const effects = {
			useEffectSetThemeDevice
		};

		//

		const state = theme;

		return {
			theme: { state, getters, setters, togglers, effects }
		};
	}
);

export const Provider = (props: TS.ProviderProps<State>) => {
	const { children, ...state } = props;

	return (
		<useThemeContext.Provider {...state}>
			<Style.Theme.Provider {...state}>{children}</Style.Theme.Provider>
		</useThemeContext.Provider>
	);
};
