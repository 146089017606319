import * as React from "react";

import * as Context from "~/context";
import * as Style from "~/style";

export const wrapRootElement = (args) => {
  const { element } = args;

	return (
		<>
			<Style.HeadStyle />
			<Context.Provider>{element}</Context.Provider>
		</>
	);
};
