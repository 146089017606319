import * as Context from "~/context";

export type ThemeName = keyof Context.Theme.State;

export type ThemeProps = {
	theme: Context.Theme.State;
	variant?: string;
};

export type ThemeValue = {
	[key in Context.Theme.State[ThemeName]]?: any;
};

export type ThemeVariant = {
	[key: string]: ThemeValue;
};

export type Bind = ReturnType<typeof bind>;

function getThemeValue(name: ThemeName, props: ThemeProps, values: ThemeValue) {
	const theme = props.theme[name];
	return values[theme];
}

export function bind(name: ThemeName, values: ThemeValue) {
	return function(props: ThemeProps) {
		return getThemeValue(name, props, values);
	};
}

bind.variants = function(name: ThemeName, values: ThemeVariant) {
	return function(props: ThemeProps) {
		const { variant } = props;
		if (!variant) {
			throw new Error("Error: variant not defined");
		} else if (typeof variant !== "string") {
			throw new Error("Error: invalid variant type, wanted `string`");
		}
		const variantValues = values[variant];
		return getThemeValue(name, props, variantValues);
	};
};
