import * as React from "react";

import * as Helpers from "~/context/helpers";
import * as TS from "../../ts";

// DELETE THIS LATER - THE THUMBNAIL SHOULD BE AVAILABLE THROUGH BACK-END
import dummyThumbnail from "~/images/result-dummy.jpeg";
// DELETE THIS LATER - THE THUMBNAIL SHOULD BE AVAILABLE THROUGH BACK-END

export type Topic = {
	id: number;
	content: string;
	link: string;
	thumbnail: string;
};

type State = {
	topics: Topic[];
	modalOpen: boolean;
	currentTopic: Topic;
};

const initialState = Object.freeze<State>({
	topics: [
		{
			id: 1,
			content: "What advice do you have for new graduates?",
			link: "https://www.youtube.com/watch?v=BVH2XuDcIeY",
			thumbnail: dummyThumbnail
		},
		{
			id: 2,
			content: "What is your most important advice for starting a business?",
			link: "https://www.youtube.com/watch?v=pzvJ9-jMjMI&t=1s",
			thumbnail: dummyThumbnail
		},
		{
			id: 3,
			content:
				"What is your advice for students struggling with a bad financial status??",
			link: "https://www.youtube.com/watch?v=7PhBbhsWpOI",
			thumbnail: dummyThumbnail
		},
		{
			id: 4,
			content: "How do I deal with fear?",
			link: "https://www.youtube.com/watch?v=mgpJ0z39K4U",
			thumbnail: dummyThumbnail
		},
		{
			id: 5,
			content: "What's the worst advice you've ever heard?",
			link: "https://www.youtube.com/watch?v=x4JTxEji14A",
			thumbnail: dummyThumbnail
		},
		{
			id: 6,
			content: "What's the one question you should ask in interviews?",
			link: "https://www.youtube.com/watch?v=kYPkCWREPy0&t=2s",
			thumbnail: dummyThumbnail
		},
		{
			id: 7,
			content: "How do I combat indecision in my life?",
			link: "https://www.youtube.com/watch?v=v8cBTK45Uqw",
			thumbnail: dummyThumbnail
		},
		{
			id: 8,
			content:
				"How long should I continue pursuing my dream until I realize it's a bust??",
			link: "https://www.youtube.com/watch?v=hav-4z-x9XA",
			thumbnail: dummyThumbnail
		},
		{
			id: 9,
			content: "How do I know what fires me up?",
			link: "https://www.youtube.com/watch?v=d0N4nH_iFb0",
			thumbnail: dummyThumbnail
		},
		{
			id: 10,
			content: "What is the formula for happiness?",
			link:
				"https://www.youtube.com/watch?v=1ClbiB7_YYg&list=PLfA33-E9P7FB-kLb1jFxMKuZ5VB3KvPNC&index=8",
			thumbnail: dummyThumbnail
		},
		{
			id: 11,
			content: "Am I too old to make it happen?",
			link:
				"https://www.youtube.com/watch?v=stD9RycSXiU&list=PLfA33-E9P7FB-kLb1jFxMKuZ5VB3KvPNC&index=12",
			thumbnail: dummyThumbnail
		},
		{
			id: 12,
			content:
				"What motivates you to develop the passion and drive to chase after something?",
			link:
				"https://www.youtube.com/watch?v=E6M-XUW4xYY&list=PLfA33-E9P7FB-kLb1jFxMKuZ5VB3KvPNC&index=15",
			thumbnail: dummyThumbnail
		}
	],
	modalOpen: false,
	currentTopic: {} as Topic
});

export const useHomeContext = Helpers.createUseContext(
	(props: Partial<State> = initialState) => {
		const [home, _setHome] = React.useState({ ...initialState, ...props });

		function setHome(state: Partial<State>): void {
			return _setHome({ ...home, ...state });
		}

		// --- Setters --- //

		function closeModal(): void {
			return setHome({
				modalOpen: false
			});
		}

		function openModalAndSetCurrentTopic(currentTopic: Topic): void {
			return setHome({
				modalOpen: true,
				currentTopic
			});
		}

		// --- Exports --- //

		const setters = {
			setHome,
			closeModal,
			openModalAndSetCurrentTopic
		};

		return {
			home: { state: home, setters }
		};
	}
);

export const Provider = (props: TS.ProviderProps<State>) => {
	const { children, ...state } = props;
	return <useHomeContext.Provider {...state}>{children}</useHomeContext.Provider>;
};
