import * as React from "react";

import * as Helpers from "~/context/helpers";
import * as TS from "~/context/ts";

import { LocationSensorState } from "react-use/lib/useLocation";

export type EventState = LocationSensorState;
export type State = {} & EventState;

export type Domain = ReturnType<typeof useLocationContext>["location"];

export const initialState: State = Object.freeze<State>({
	trigger: "",
	state: {},
	length: 0,
	hash: "",
	host: "",
	hostname: "",
	href: "",
	origin: "",
	pathname: "",
	port: "",
	protocol: "",
	search: ""
});

export const useLocationContext = Helpers.createUseContext(
	(props: Partial<State> = initialState) => {
		const [location, _setLocation] = React.useState({ ...initialState, ...props });

		// --- Setters ---

		function setLocation(state: Partial<State>): void {
			return _setLocation({ ...location, ...state });
		}

		// --- Exports ---

		const state = location;

		const actions = {
			setLocation
		};

		return {
			location: { state, actions }
		};
	}
);

export const Provider = (props: React.PropsWithChildren<State>) => {
	const { children } = props;

	return <useLocationContext.Provider>{children}</useLocationContext.Provider>;
};
