import { createGlobalStyle } from "styled-components";

export const Fonts = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css?family=Roboto:400,700&display=swap');

	html, body {
    font-family: "Roboto", -apple-system, BlinkMacSystemFont, 'Segoe UI', Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    background-color: #2E2E2E;
  }

  * {
    box-sizing: border-box;
  }
`;
