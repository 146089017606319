import * as React from "react";
import moment from "moment";

import * as Helpers from "~/context/helpers";

// --- Types --- //

export type Weekday = {
	id: string;
	day: string;
};

export type Month = {
	id: string;
	name: string;
};

export type Year = {
	id: string;
	number: number;
};

type DaysInMonths = {
	[key: string]: number;
};

type State = {
	months: Month[];
	years: Year[];
	weekdays: Weekday[];
	daysInMonths: DaysInMonths;
	prevMonth: string;
	currentMonth: string;
	nextMonth: string;
	currentYear: number;
	startMonth: number;
	startDay: number;
	startYear: number;
	endMonth: number;
	endDay: number;
	endYear: number;
	startDaySelected: boolean;
	endDaySelected: boolean;
	dateDropdown: boolean;
	sort: string;
	platform: string;
};

// --- Variables / State --- //

const months = [
	"January",
	"February",
	"March",
	"April",
	"May",
	"June",
	"July",
	"August",
	"September",
	"October",
	"November",
	"December"
];

const years = [] as Year[];
const currentYear = new Date().getFullYear();
for (let year = 2000; year <= currentYear; year++) {
	years.push({ id: `${year}`, number: year });
}

const initialState = Object.freeze<State>({
	months: [
		{ id: "January", name: "January" },
		{ id: "February", name: "February" },
		{ id: "March", name: "March" },
		{ id: "April", name: "April" },
		{ id: "May", name: "May" },
		{ id: "June", name: "June" },
		{ id: "July", name: "July" },
		{ id: "August", name: "August" },
		{ id: "September", name: "September" },
		{ id: "October", name: "October" },
		{ id: "November", name: "November" },
		{ id: "December", name: "December" }
	],
	years,
	weekdays: [
		{ id: "Sunday", day: "Su" },
		{ id: "Monday", day: "Mo" },
		{ id: "Tuesday", day: "Tu" },
		{ id: "Wednesday", day: "We" },
		{ id: "Thursday", day: "Th" },
		{ id: "Friday", day: "Fr" },
		{ id: "Saturday", day: "Sa" }
	],
	daysInMonths: {
		January: 31,
		February: 28,
		March: 31,
		April: 30,
		May: 31,
		June: 30,
		July: 31,
		August: 31,
		September: 30,
		October: 31,
		November: 30,
		December: 31
	},
	prevMonth: months[new Date().getMonth() - 1],
	currentMonth: months[new Date().getMonth()],
	nextMonth: months[new Date().getMonth() + 1],
	currentYear: new Date().getFullYear(),
	startMonth: new Date().getMonth(),
	startDay: new Date().getDate(),
	startYear: new Date().getFullYear(),
	endMonth: new Date().getMonth(),
	endDay: new Date().getDate(),
	endYear: new Date().getFullYear(),
	startDaySelected: false,
	endDaySelected: false,
	dateDropdown: false,
	sort: "relevance",
	platform: "youtube"
});

// --- Context --- //

export const useFiltersContext = Helpers.createUseContext(() => {
	const [filters, _setFilters] = React.useState<State>({ ...initialState });
	const setFilters = (state: Partial<State>) => _setFilters({ ...filters, ...state });

	const {
		currentMonth,
		currentYear,
		startMonth,
		startDay,
		startYear,
		endMonth,
		endDay,
		endYear,
		startDaySelected,
		daysInMonths,
		dateDropdown
	} = filters;

	// --- Getters --- //
	function getFirstDayOfMonth() {
		// Finds the weekday in which the first day of the month falls under.
		const year = currentYear;
		const month = months.indexOf(currentMonth);
		const firstDayOfMonth = new Date(year, month, 1).getDay();
		return firstDayOfMonth;
	}

	// --- Setters --- //
	function setRange(month: number, day: number, year: number): void {
		if (!startDaySelected) {
			setFilters({
				startMonth: month,
				startDay: day,
				startYear: year,
				startDaySelected: true,
				endDay: day,
				endDaySelected: false
			});
		} else {
			setFilters({
				endMonth: month,
				endDay: day,
				endYear: year,
				startDaySelected: false,
				endDaySelected: true
			});
		}
	}

	function fixOffsetLogic(): void {
		// Fixes end day value when it's greater than the start day.
		if (startYear > endYear) {
			setFilters({ endYear: startYear });
		}

		if (startYear === endYear) {
			if (startMonth > endMonth) {
				setFilters({ endMonth: startMonth });
			}
		}

		if (startMonth === endMonth) {
			if (startDay > endDay) {
				setFilters({ endDay: startDay });
			}
		}
	}

	function setMonth(month: string): void {
		setFilters({ currentMonth: month });
	}

	function setYear(year: number): void {
		return setFilters({ currentYear: year });
	}

	function setMonthPrev(): void {
		let currentMonthIndex = months.indexOf(currentMonth);
		if (currentMonthIndex === 0) currentMonthIndex = 12;

		let previousMonthIndex = currentMonthIndex - 1;
		if (previousMonthIndex === 0) previousMonthIndex = 12;

		let nextMonthIndex = currentMonthIndex + 1;
		if (nextMonthIndex === 13) nextMonthIndex = 1;

		return setFilters({
			prevMonth: months[previousMonthIndex - 1],
			currentMonth: months[currentMonthIndex - 1],
			nextMonth: months[nextMonthIndex - 1],
			currentYear: currentMonth === "January" ? currentYear - 1 : currentYear
		});
	}

	function setMonthNext(): void {
		let currentMonthIndex = months.indexOf(currentMonth);
		if (currentMonthIndex === 11) currentMonthIndex = -1;

		let previousMonthIndex = currentMonthIndex - 1;
		if (previousMonthIndex === -2) previousMonthIndex = 10;

		let nextMonthIndex = currentMonthIndex + 1;
		if (nextMonthIndex === 11) nextMonthIndex = -1;

		return setFilters({
			prevMonth: months[previousMonthIndex + 1],
			currentMonth: months[currentMonthIndex + 1],
			nextMonth: months[nextMonthIndex + 1],
			currentYear: currentMonth === "December" ? currentYear + 1 : currentYear
		});
	}

	function setLeapYear(): void {
		return setFilters({
			daysInMonths: {
				...daysInMonths,
				February: moment([currentYear]).isLeapYear() ? 29 : 28
			}
		});
	}

	function toggleDateDropdown(): void {
		return setFilters({ dateDropdown: !dateDropdown });
	}

	function setSort(sort: string): void {
		return setFilters({ sort });
	}

	function setPlatform(platform: string): void {
		return setFilters({ platform });
	}

	// --- Handlers --- //

	function handleSubmit(): void {
		console.log("Filter submitted!");
	}

	// --- Exports --- //
	const state = filters;

	const getters = {
		getFirstDayOfMonth
	};

	const setters = {
		setFilters,
		setRange,
		fixOffsetLogic,
		setMonth,
		setYear,
		setMonthPrev,
		setMonthNext,
		setLeapYear,
		toggleDateDropdown,
		setSort,
		setPlatform
	};

	const handlers = { handleSubmit };

	const variables = { months };

	return {
		filters: { state, getters, setters, handlers, variables }
	};
});

export const Provider = (props: React.PropsWithChildren<State>) => {
	const { children } = props;
	return <useFiltersContext.Provider>{children}</useFiltersContext.Provider>;
};
