import * as React from "react";

import * as Helpers from "~/context/helpers";
import * as TS from "~/context/ts";

export type State = {
	active: boolean;
};
export type Domain = ReturnType<typeof useHeaderContext>["header"];

export const initialState = Object.freeze<State>({
	active: false
});

export const useHeaderContext = Helpers.createUseContext(
	(props: Partial<State> = initialState) => {
		const [header, _setHeader] = React.useState({
			...initialState,
			...props
		});

		// --- Getters ---

		function getIsActive(): boolean {
			return header.active;
		}

		// --- Setters ---

		function setHeader(state: Partial<State>): void {
			return _setHeader({ ...header, ...state });
		}

		function setHeaderActive(active: boolean): void {
			return setHeader({ active });
		}

		// --- Toggles ---

		function toggleHeaderActive(): void {
			return setHeaderActive(!header.active);
		}

		// --- Exports ---

		const getters = {
			getIsActive
		};

		const setters = {
			setHeader,
			setHeaderActive
		};

		const toggles = {
			toggleHeaderActive
		};

		//

		const state = header;

		const actions = {
			...setters,
			...toggles
		};

		return {
			header: { ...getters, state, actions }
		};
	}
);

export const Provider = (props: React.PropsWithChildren<State>) => {
	const { children, ...state } = props;
	return <useHeaderContext.Provider {...state}>{children}</useHeaderContext.Provider>;
};
