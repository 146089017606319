import Colors from "./_colors";

export const breakpoints = {
	// Extra small devices (phones)
	mobile: {
		min: 300,
		max: 766,
	},
	// Small devices (tablets)
	tablet: {
		min: 767,
		max: 991,
	},
	// Medium devices (desktops)
	desktop: {
		min: 992,
		max: 1199,
	},
	// Large devices (large desktops)
	ultrawide: {
		min: 1200,
		max: Infinity,
	},
};

export const layout = {
	maxWidth: 1450,
	ultrawide: {
		padding: 120,
	},
	desktop: {
		padding: 120,
	},
	tablet: {
		padding: 60,
	},
	mobile: {
		padding: 30,
	},
};

export const animation = {
	duration: {
		fast: 180,
	},
};

export const icons = {
	small: 15,
	medium: 20,
	large: 25,
};

export const globalColors = {
	socials: {
		muted: Colors.DARK_TEXT,
		mutedDark: Colors.DARK2,
		facebook: Colors.FACEBOOK,
		instagram: Colors.INSTAGRAM,
		twitter: Colors.TWITTER,
		youtube: Colors.YOUTUBE,
		tiktok: Colors.TIKTOK,
	},
};

// ================== //
// ↓↓↓ Components ↓↓↓ //
// ================== //

export const home = {
	light: {},
	dark: {
		backgroundColor: Colors.DARK3,
		cardModalOverlayBackgroundColor: "rgba(0, 0, 0, 0.6)",
		header: {
			backgroundColor: Colors.DARK3,
			titleColor: Colors.DARK_TEXT,
			subtitleColor: Colors.DARK_TEXT,
			searchBackgroundColor: Colors.HOME_SEARCH,
			searchIconColor: Colors.HOME_SEARCH_TEXT,
			searchPlaceholderColor: Colors.HOME_SEARCH_TEXT,
		},
		handpicked: {
			backgroundColor: Colors.DARK1,
			titleColor: Colors.DARK_TEXT,
			card: {
				backgroundColor: Colors.DARK3,
				textColor: Colors.DARK_TEXT,
			},
		},
		handpickedCardModal: {
			backgroundColor: Colors.DARK2,
			titleColor: Colors.DARK_TEXT,
		},
		description: {
			color: Colors.DARK_TEXT,
			backgroundColor: Colors.DARK2,
			exampleBackgroundColor: Colors.HOME_SEARCH,
			exampleShadow: "4px 4px 8px 0px rgba(0, 0, 0, 0.2)",
			exampleIconColor: Colors.HOME_SEARCH_TEXT,
			exampleTextColor: Colors.HOME_SEARCH_TEXT,
		},
		tutorial: {
			backgroundColor: Colors.DARK3,
			titleColor: Colors.DARK_TEXT,
			videoDummyBackgroundColor: Colors.DARK5,
		},
	},
};

export const search = {
	mobile: {
		height: 60,
		borderRadius: 10,
		icon: 18,
	},
	height: 100,
	maxWidth: 800,
	input: {
		height: 60,
		padding: 30,
	},
	drawer: {
		height: 160,
	},
	light: {},
	dark: {
		backgroundColor: Colors.DARK3,
		barBackgroundColor: Colors.DARK3,
		barColorSearching: Colors.PRIMARY,
		barColorNotSearching: Colors.DARK_TEXT,
		resultsBackgroundColor: Colors.DARK2,
		resultsHeaderCount: Colors.DARK_TEXT,
		resultsHeaderFilter: Colors.DARK5,
		buttonColor: Colors.DARK_TEXT,
		buttonBackgroundColor: Colors.DARK3,
		buttonShadow: "0px 4px 8px 0px rgba(0, 0, 0, 0.4)",
		filterOverlayBackgroundColor: "rgba(0, 0, 0, 0.7)",
		resultCards: {
			backgroundColor: Colors.DARK3,
			shadow: "0px 4px 8px 0px rgba(0, 0, 0, 0.4)",
			thumbnailContainer: Colors.DARK3,
			thumbnailContainerShadow: "0px 4px 8px 0px rgba(0, 0, 0, 0.2)",
			iconBackgroundColor: Colors.DARK3,
			cardDataTranscriptColor: Colors.DARK_TEXT,
			cardDataDateColor: Colors.DARK_TEXT,
		},
		tray: {
			backgroundColor: Colors.DARK4,
			headerButtonLinkColor: Colors.DARK_TEXT,
			headerButtonLinkBackgroundColor: Colors.DARK5,
			headerButtonCloseColor: Colors.DARK_TEXT,
			headerButtonCloseBackgroundColor: Colors.DARK5,
			headerTitleColor: Colors.DARK_TEXT,
			contentCardColor: Colors.DARK_TEXT,
			contentCardBackgroundColor: Colors.DARK5,
		},
	},
};

export const filter = {
	light: {},
	dark: {
		backgroundColor: Colors.DARK2,
		closeButtonColor: Colors.DARK2,
		submitButtonColor: Colors.DARK_TEXT,
		submitButtonBackgroundColor: Colors.DARK4,
		calendarDropdownColor: Colors.DARK_TEXT,
		calendarDropdownBackgroundColor: Colors.DARK2,
		calendarDropdownCloseColor: Colors.PRIMARY,
		calendarDropdownBodyOptionActiveBackgroundColor: Colors.DARK3,
		date: {
			titleColor: Colors.PRIMARY,
			containerBackgroundColor: Colors.DARK3,
			backgroundColor: Colors.DARK5,
			calendarColor: Colors.DARK_TEXT,
			calendarBodyWeekdaysDayColor: Colors.DARK2,
			calendarBodyDaysDayMutedColor: Colors.DARK3,
			calendarBodyDaysDayUnmutedColor: Colors.DARK_TEXT,
			calendarBodyDaysDayBackgroundColor: "rgba(255, 255, 255, 0.5)",
			rangeColor: Colors.DARK_TEXT,
			rangeBackgroundColor: Colors.DARK4,
		},
		sort: {
			backgroundColor: Colors.DARK3,
			titleColor: Colors.PRIMARY,
			buttonBackgroundColorSelected: Colors.DARK2,
			buttonBackgroundColorNotSelected: Colors.DARK5,
			buttonBorderColorSelected: Colors.BLACK,
			buttonBorderColorNotSelected: Colors.DARK5,
			buttonTextColorSelected: Colors.HOME_SEARCH_TEXT,
			buttonTextColorNotSelected: Colors.DARK3,
		},
		platform: {
			titleColor: Colors.PRIMARY,
			buttonBackgroundColor: Colors.DARK2,
			buttonBorderColor: Colors.DARK3,
			buttonShadow: "0px 4px 8px 0px rgba(0, 0, 0, 0.4)",
		},
	},
};

export const result = {
	light: {},
	dark: {
		color: Colors.DARK_TEXT,
		backgroundColor: Colors.DARK2,
		boxShadow: "0px 4px 16px 0px rgba(0,0,0,0.3)",
		backButtonColor: Colors.WHITE,
		backButtonBackgroundColor: "rgba(255, 255, 255, 0.3)",
		homeLinkColor: Colors.DARK_TEXT,
		homeLinkBackgroundColor: Colors.DARK4,
		homeLinkActiveBackgroundColor: "rgba(255, 255, 255, 0.5)",
		video: {
			backgroundColor: Colors.DARK4,
			overlayBackgroundColor: "rgba(0, 0, 0, 40%)",
			playButtonColor: "rgba(255, 255, 255, 0.6)",
		},
		next: {
			backgroundColor: Colors.DARK3,
			titleColor: Colors.DARK_TEXT_MUTED,
			contentColor: Colors.DARK_TEXT_MUTED,
			contentThumbnailShadow: "0px 4px 8px 0px rgba(0, 0, 0, 0.4)",
			contentThumbnailIconColor: Colors.DARK_TEXT,
			contentThumbnailIconBackgroundColor: Colors.DARK3,
			contentInfoTitleColor: Colors.DARK_TEXT,
		},
	},
};

export const footer = {
	mobile: {
		padding: 70,
		iconHeight: 38,
	},
	light: {},
	dark: {
		backgroundColor: Colors.DARK1,
		topButtonColor: Colors.DARK_TEXT,
		topButtonBackgroundColor: Colors.DARK4,
		topButtonBorderColor: Colors.BORDER1,
		topButtonShadow: "box-shadow: 4px 4px 8px 0px rgba(0,0,0,0.4);",
		navTitleColor: Colors.DARK4,
		navLocalLinkColor: Colors.DARK_TEXT,
		navExternalLinkColor: Colors.DARK_TEXT,
		socialsBackgroundColor: Colors.DARK_TEXT,
	},
};

export const loading = {
	light: {},
	dark: {
		backgroundColor: Colors.DARK2,
	},
};
