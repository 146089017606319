import * as Polished from "polished";
import styled, { createGlobalStyle } from "styled-components";

import * as Common from "../common";

export const Misc = createGlobalStyle`
  * {
    text-decoration: none;
    box-sizing: border-box;
  }

  html,
  body {
    overscroll-behavior-y: contain;
    margin: 0;
    padding: 0;
    height: 100%;
    width: 100%;
    position: relative;
    user-select: none;
  }

  html {
    font-size: 16px;

    @media screen and (min-width: 900px) {
      font-size: 18px;
    }

    @media screen and (min-width: 1200px) {
      font-size: 20px;
    }
  }

  #root {
    position: relative;
    overflow: hidden;
    height: 100%;
  }

  input {
    ${Common.Snippets.transition("outline", Common.Constants.animation.duration.fast)}
    ${Common.Snippets.override("input")}
    outline-offset: 0px;
    outline-width: 2px;
    outline-style: solid;
    outline-color: transparent;

    :focus {
      outline-color: ${Polished.opacify(-0.65, Common.Colors.DARK5)};
    }
  }
  

  /* * { border: 1px red solid }

  * { background: rgba(252, 211, 3, 0.15); }
  *, * { background: rgba(252, 211, 3, 0.15); }
  *, *, * { background: rgba(252, 211, 3, 0.15); }
  *, *, *, *{ background: rgba(252, 211, 3, 0.15); }
  *, *, *, *, * { background: rgba(252, 211, 3, 0.15); }
  *, *, *, *, *, * { background: rgba(252, 211, 3, 0.15); }
  *, *, *, *, *, *, * { background: rgba(252, 211, 3, 0.15); }
  *, *, *, *, *, *, *, * { background: rgba(252, 211, 3, 0.15); }
  *, *, *, *, *, *, *, *, * { background: rgba(252, 211, 3, 0.15); }
  *, *, *, *, *, *, *, *, *, * { background: rgba(252, 211, 3, 0.15); }
  *, *, *, *, *, *, *, *, *, *, * { background: rgba(252, 211, 3, 0.15); }
  *, *, *, *, *, *, *, *, *, *, *, * { background: rgba(252, 211, 3, 0.15); } */
`;
