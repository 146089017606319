import * as React from "react";

import * as Helpers from "../../helpers";
import * as TS from "../../ts";

type State = {
	loaded: boolean;
	spin: boolean;
};

const initialState = Object.freeze<State>({
	loaded: false,
	spin: true,
});

export const useLoadingContext = Helpers.createUseContext(
	(props: Partial<State> = initialState) => {
		const [loading, _setLoading] = React.useState({ ...initialState, ...props });

		function setLoading(state: Partial<State>): void {
			return _setLoading({ ...loading, ...state });
		}

		// --- Setters ---

		function setLoaded(loaded: boolean): void {
			return setLoading({ loaded });
		}

		function toggleSpin(): void {
			return setLoading({ spin: !loading.spin });
		}

		// --- Exports --- //

		const setters = {
			setLoading,
			setLoaded,
			toggleSpin,
		};

		return {
			loading: { state: loading, setters },
		};
	}
);

export const Provider = (props: TS.ProviderProps<State>) => {
	const { children, ...state } = props;
	return <useLoadingContext.Provider {...state}>{children}</useLoadingContext.Provider>;
};
