import * as React from "react";

import * as Head from "~/style/lib/head";

export const HeadStyle = () => (
	<>
		<Head.Fonts />
		<Head.MiniReset />
		<Head.Misc />
		<Head.Normalize />
	</>
);
