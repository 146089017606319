import * as React from "react";
import styled, { ThemeProvider } from "styled-components";

import * as Context from "~/context";

//TODO: fix children being passed as an key:value pair in context state.
export const Provider = (props: Context.ProviderProps<Context.Theme.State>) => {
	const { children } = props;
	const { theme } = Context.Theme.useThemeContext();

	return <ThemeProvider theme={theme.state}>{children}</ThemeProvider>;
};
