import * as React from "react";

import * as Helpers from "~/context/helpers";
import * as TS from "~/context/ts";

export type EventState = {
	width: number;
	height: number;
};

export type State = {} & EventState;

export type Domain = ReturnType<typeof useWindowSizeContext>["windowSize"];

export type Props = {
	children: React.ReactNode;
};

export const initialState: State = Object.freeze({
	width: 0,
	height: 0
});

export const useWindowSizeContext = Helpers.createUseContext(
	(props: Partial<State> = initialState) => {
		const [windowSize, _setWindowSize] = React.useState({
			...initialState,
			...props
		});

		// --- Getters ---

		function getWindowSize(): State {
			return windowSize;
		}

		function getWindowWidth(): State["width"] {
			return windowSize.width
		}
		
		function getWindowHeight(): State["height"] {
			return windowSize.height
		}

		// --- Setters ---

		function setWindowSize(state: Partial<State>): void {
			return _setWindowSize({ ...windowSize, ...state });
		}

		// --- Exports ---

		const getters = {
			getWindowSize,
			getWindowWidth,
			getWindowHeight
		};

		const setters = {
			setWindowSize
		};

		//

		const state = windowSize;

		const actions = {
			...setters
		};

		return {
			windowSize: { ...getters, state, actions }
		};
	}
);

export const Provider = (props: React.PropsWithChildren<State>) => {
	const { children, ...state } = props;
	return (
		<useWindowSizeContext.Provider {...state}>{children}</useWindowSizeContext.Provider>
	);
};
