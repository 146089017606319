import * as React from "react";

import * as Helpers from "~/context/helpers";
import * as TS from "~/context/ts";
import * as Utils from "~/utils";

// --- Icons ---
import { Facebook as FacebookIcon } from "@styled-icons/boxicons-logos/Facebook";
import { Twitter as TwitterIcon } from "@styled-icons/boxicons-logos/Twitter";
import { Instagram as InstagramIcon } from "@styled-icons/boxicons-logos/Instagram";
import { Youtube as YoutubeIcon } from "@styled-icons/boxicons-logos/Youtube";

export type State = {
	routes: Route[];
	socials: Social[];
	scrollTopCurated: number;
	scrollTopCatalog: number;
	nodes: Nodes;
};

export type Nodes = {
	menu: Menu;
	objective: Objective;
};

export type Domain = ReturnType<typeof useNavigationContext>["navigation"];

export type Route = {
	path: string;
	label: string;
};

export type Social = {
	label: string;
	url: string;
	icon: React.ForwardRefExoticComponent<any>;
};

export type Menu = {
	active: boolean;
	position: Position;
};

//TODO: type safe this.
export type Objective = {
	engaged: string;
	items: any[];
};

type Position = ClientRect;

export const routes = [
	{ path: "featured", label: "featured", section: "curated" },
	{ path: "search", label: "search", section: "catalog" }
];

export const socials = [
	{
		label: "Facebook",
		url: "https://www.facebook.com/gary",
		icon: FacebookIcon
	},
	{
		label: "Instagram",
		url: "https://www.instagram.com/garyvee",
		icon: InstagramIcon
	},
	{
		label: "YouTube",
		url: "https://www.youtube.com/user/GaryVaynerchuk",
		icon: YoutubeIcon
	},
	{
		label: "Twitter",
		url: "https://twitter.com/garyvee",
		icon: TwitterIcon
	}
];

export const initialState = Object.freeze({
	routes,
	socials,
	scrollTopCurated: 0,
	scrollTopCatalog: 0,
	nodes: {
		menu: {
			active: false,
			position: {} as Position
		},
		objective: {
			engaged: "",
			items: []
		}
	}
});

export const useNavigationContext = Helpers.createUseContext(
	(props: Partial<State> = initialState) => {
		const [navigation, _setNavigation] = React.useState({
			...initialState,
			...props
		});

		// --- Getters ---

		function getRoutes(): Route[] {
			return navigation.routes;
		}

		function getSocials(): Social[] {
			return navigation.socials;
		}

		function getMenuPosition(): Position {
			return navigation.nodes.menu.position;
		}

		function isMenuActive(): boolean {
			return navigation.nodes.menu.active;
		}

		// --- Setters ---

		function setNavigation(state: Partial<State>): void {
			return _setNavigation({ ...navigation, ...state });
		}

		function setNode<N extends keyof Nodes, V extends Nodes[N]>(name: N, value: V): void {
			const nodes = Utils.cloneDeep(navigation.nodes);
			nodes[name] = value;
			return setNavigation({ nodes });
		}

		function setNodeDeep<
			N extends keyof Nodes,
			K extends keyof Nodes[N],
			V extends Nodes[N][K]
		>(name: N, key: K, value: V): void {
			const nodes = Utils.cloneDeep(navigation.nodes);
			nodes[name][key] = value;
			return setNavigation({ nodes });
		}

		function setNavigationMenuPosition(position: ClientRect): void {
			return setNodeDeep("menu", "position", position);
		}

		function setNavigationMenuActive(active: boolean): void {
			return setNodeDeep("menu", "active", active);
		}

		function setObjectiveEngaged(name: string): void {
			setNodeDeep("objective", "engaged", name);
		}

		function setScrollTopCurated(scrollTopCurated: number): void {
			setNavigation({ scrollTopCurated });
		}

		function setScrollTopCatalog(scrollTopCatalog: number): void {
			setNavigation({ scrollTopCatalog });
		}

		// --- Toggles ---

		function toggleNavigationMenuActive(): void {
			return setNavigationMenuActive(!navigation.nodes.menu.active);
		}

		// --- Effects ---

		function effectSetNavigationMenuPosition(ref: React.RefObject<HTMLDivElement>): void {
			const menu = ref.current;
			const rect = menu!.getBoundingClientRect();
			if (rect) {
				setNavigationMenuPosition(rect);
			}
		}

		function useEffectCreateObjectiveItem(name: string, y: number): void {
			setNodeDeep("objective", "items", [
				...navigation.nodes.objective.items,
				{ name, y }
			]);
		}

		// --- Exports ---

		const getters = {
			getRoutes,
			getSocials,
			getMenuPosition,
			isMenuActive
		};

		const setters = {
			setNavigation,
			setNavigationMenuPosition,
			setNavigationMenuActive,
			setObjectiveEngaged,
			setScrollTopCatalog,
			setScrollTopCurated
		};

		const toggles = {
			toggleNavigationMenuActive
		};

		const effects = {
			effectSetNavigationMenuPosition,
			useEffectCreateObjectiveItem
		};

		//

		const state = navigation;

		const actions = {
			...setters,
			...toggles,
			...effects
		};

		return {
			navigation: { ...getters, state, actions }
		};
	}
);

export const Provider = (props: React.PropsWithChildren<State>) => {
	const { children, ...state } = props;
	return (
		<useNavigationContext.Provider {...state}>{children}</useNavigationContext.Provider>
	);
};
