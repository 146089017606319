import * as React from "react";

import * as Helpers from "~/context/helpers";
import * as TS from "~/context/ts";

import { State as MouseState } from "react-use/lib/useMouse";

export type EventState = MouseState;
export type State = {} & EventState;

export type Domain = ReturnType<typeof useMouseContext>["mouse"];

export const initialState: State = Object.freeze({
	docX: 0,
	docY: 0,
	posX: 0,
	posY: 0,
	elX: 0,
	elY: 0,
	elH: 0,
	elW: 0
});

export const useMouseContext = Helpers.createUseContext(
	(props: Partial<State> = initialState) => {
		const [mouse, _setMouse] = React.useState({ ...initialState, ...props });

		// --- Setters ---

		function setMouse(props: Partial<State>) {
			return _setMouse({ ...mouse, ...props });
		}

		// --- Exports ---

		const state = mouse;

		const actions = {
			setMouse
		};

		return {
			mouse: { state, actions }
		};
	}
);

export const Provider = (props: React.PropsWithChildren<State>) => {
	const { children, ...state } = props;
	return <useMouseContext.Provider {...state}>{children}</useMouseContext.Provider>;
};
