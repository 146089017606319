import * as React from "react";

import * as Domains from "~/context/lib/domains";

export type ProviderProps<T> = Partial<T> & React.ReactWithChildren;
export type ProviderRecursive = {
	index: number;
	c?: React.ReactNode;
};

const providers = [
	Domains.Mouse.Provider,
	Domains.Theme.Provider,
	Domains.Scroll.Provider,
	Domains.Location.Provider,
	Domains.WindowSize.Provider,
	Domains.Header.Provider,
	Domains.Navigation.Provider,
	Domains.Home.Provider,
	Domains.Search.Provider,
	Domains.Results.Provider,
	Domains.Filters.Provider,
	Domains.Loading.Provider
];

const ProviderRecursive = (props: ProviderRecursive) => {
	const { index, c } = props;

	const current = index - 1;
	const Provider = providers[current];

	if (current === 0) {
		return <Provider>{c}</Provider>;
	}

	return (
		<Provider>
			<ProviderRecursive index={current} c={c} />
		</Provider>
	);
};

export const Provider = (props: React.ReactWithChildren) => {
	const { children } = props;
	return <ProviderRecursive index={providers.length} c={children} />;
};
