const Colors = Object.freeze({
	WHITE: "#FFFFFF",
	BLACK: "#292727",
	PRIMARY: "#99A2CD",
	DARK1: "#272A2F",
	DARK2: "#2E2E2E",
	DARK3: "#404040",
	DARK4: "#515151",
	DARK5: "#6A6969",
	DARK_TEXT: "#DFDFDF",
	DARK_TEXT_MUTED: "#767676",
	BORDER1: "#626262",
	BORDER2: "#C8C8C8",
	HOME_SEARCH: "rgba(143, 146, 161, 0.6)",
	HOME_SEARCH_TEXT: "#D6D8E5",
	INSTAGRAM: "#B631B8",
	FACEBOOK: "#1A89CE",
	YOUTUBE: "#FF0000",
	TWITTER: "#19A2F2",
	TIKTOK: "#8EF8FF",
	TRANSPARENT: "rgba(0,0,0,0)"
});

export default Colors;
