import * as React from "react";

function warnNoProvider() {
	console.warn("[constate] Missing Provider");
}

const canUseProxy =
	process.env.NODE_ENV === "development" && typeof Proxy !== "undefined";

const defaultValue = canUseProxy
	? new Proxy({}, { get: warnNoProvider, apply: warnNoProvider })
	: {};

export function createUseContext<P, V = any>(useValue: (props: P) => V) {
	const Context = React.createContext(defaultValue as V);

	const Provider: React.FunctionComponent<P> = (props) => {
		const value = useValue(props);
		return <Context.Provider value={value}>{props.children}</Context.Provider>;
	};

	if (useValue.name) {
		Context.displayName = `${useValue.name}.Context`;
		Provider.displayName = `${useValue.name}.Provider`;
	}

	const useContext = () => React.useContext(Context);
	useContext.Context = Context;
	useContext.Provider = Provider;
	return useContext;
}
