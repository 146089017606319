import * as React from "react";
import axios from "axios";

import * as Helpers from "~/context/helpers";
import * as TS from "../../ts";

// DELETE THIS LATER - THE THUMBNAIL SHOULD BE AVAILABLE THROUGH BACK-END
import dummyThumbnail from "~/images/result-dummy.jpeg";
// DELETE THIS LATER - THE THUMBNAIL SHOULD BE AVAILABLE THROUGH BACK-END

export type Context = {
	highlight: string;
	sentence: string;
}

export type Result = {
	end_time: number;
	privacy: string;
	score: number;
	start_time: number;
	text: string;
	video_id: string;
	video_publishedAt: string;
	video_title: string;
}

type State = {
	results: Result[];
	current: Result;
};

const initialState = Object.freeze<State>({
	results: [] as Result[],
	current: {} as Result
});

export const useResultsContext = Helpers.createUseContext(() => {
	const [results, _setResults] = React.useState<State>({ ...initialState });
	const setResults = (state: Partial<State>) => _setResults({ ...results, ...state });

	// --- Getters --- //

	async function getResults(search: string): Promise<void> {
		try {
			const response = await fetch(`/api/search?value=${search}`)
			const results = await response.json();
			console.log(response);
			setResults({ results });
		} catch (error) {
			console.error(error);
		}
	}

	// --- Setters --- //

	function setCurrent(
		id: string,
		title: string,
		link: string,
		platform: string,
		transcript: string,
		date: string,
		duration: string,
		thumbnail: string
	): void {
		return setResults({
			current: { id, title, link, platform, transcript, date, duration, thumbnail },
		});
	}

	// --- Handlers --- //

	function handlePlayVideo(): void {
		return console.log("Play the video!");
	}

	// --- Exports --- //

	const state = results;

	const getters = {
		getResults,
	};

	const setters = {
		setResults,
		setCurrent,
	};

	const handlers = {
		handlePlayVideo,
	};

	return {
		results: { state, getters, setters, handlers },
	};
});

export const Provider = (props: TS.ProviderProps<State>) => {
	const { children, ...state } = props;
	return <useResultsContext.Provider {...state}>{children}</useResultsContext.Provider>;
};
