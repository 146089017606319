import * as React from "react";
import axios from "axios";

import * as Helpers from "../../helpers";
import * as TS from "../../ts";

export type Tray = {
	// TODO: Change this when we get our real data structure.
	id: number;
	content: string;
	link: string;
};

type State = {
	recommendations: Tray[];
	suggestions: Tray[];
	trayType: string;
	searching: boolean;
	input: string;
	filterModal: boolean;
};

const initialState = Object.freeze<State>({
	recommendations: [] as Tray[],
	suggestions: [] as Tray[],
	trayType: "Recommendations",
	searching: false,
	input: "",
	filterModal: false,
});

export const useSearchContext = Helpers.createUseContext(
	(props: Partial<State> = initialState) => {
		const [search, _setSearch] = React.useState({ ...initialState, ...props });

		function setSearch(state: Partial<State>): void {
			return _setSearch({ ...search, ...state });
		}

		// --- Getters ---

		function getRecommendations(): void {
			axios
				.get("/api/recommendations")
				.then((response) => {
					const recommendations = response.data.recommendations;
					return setSearch({ recommendations });
				})
				.catch((error) => console.log(error));
		}

		function getSuggestions() {
			axios
				.get("/api/suggestions")
				.then((response) => {
					const suggestions = response.data.suggestions;
					return setSearch({ suggestions, trayType: "Suggestions" });
				})
				.catch((error) => console.log(error));
		}

		// --- Setters ---

		function setTrayToRecommendations(): void {
			setSearch({ trayType: "Recommendations" });
		}

		function setTrayToSuggestions(): void {
			setSearch({ trayType: "Suggestions" });
		}

		function setSearching(searching: boolean): void {
			setSearch({ searching });
		}

		function setFilterModal(filterModal: boolean): void {
			setSearch({ filterModal });
		}

		// --- Exports ---

		const getters = {
			getRecommendations,
			getSuggestions,
		};

		const setters = {
			setSearch,
			setTrayToRecommendations,
			setTrayToSuggestions,
			setSearching,
			setFilterModal,
		};

		return {
			search: { state: search, getters, setters },
		};
	}
);

export const Provider = (props: TS.ProviderProps<State>) => {
	const { children, ...state } = props;
	return <useSearchContext.Provider {...state}>{children}</useSearchContext.Provider>;
};
