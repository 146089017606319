import * as React from "react";

import * as Helpers from "~/context/helpers";
import * as TS from "~/context/ts";

import { State as ScrollState } from "react-use/lib/useScroll";

export type EventState = ScrollState;

export type State = {
	dirX: ScrollDirectionX;
	dirY: ScrollDirectionY;
	scrollTop: string;
} & EventState;

export type Domain = ReturnType<typeof useScrollContext>["scroll"];

export type ScrollDirectionXOptions<
	D extends "lateral" | "vertical"
> = typeof directions[D][keyof typeof directions[D]];

export type ScrollDirectionX = ScrollDirectionXOptions<"lateral">;
export type ScrollDirectionY = ScrollDirectionXOptions<"vertical">;

export const directions = {
	vertical: {
		up: "up" as "up",
		down: "down" as "down"
	},
	lateral: {
		left: "left" as "left",
		right: "right" as "right"
	}
};

export const initalState: State = Object.freeze({
	x: 0,
	y: 0,
	dirX: directions.lateral.right,
	dirY: directions.vertical.down,
	scrollTop: ""
});

export const useScrollContext = Helpers.createUseContext(
	(props: Partial<State> = initalState) => {
		const [scroll, _setScroll] = React.useState({
			...initalState,
			...props
		});

		// --- Setters ---

		function setScroll(state: Partial<State>): void {
			return _setScroll({ ...scroll, ...state });
		}

		function setScrollDirectionX(dirX: ScrollDirectionX): void {
			return setScroll({ dirX });
		}

		function setScrollDirectionY(dirY: ScrollDirectionY): void {
			return setScroll({ dirY });
		}

		function setScrollTop(scrollTop: string): void {
			return setScroll({ scrollTop });
		}

		// --- Effects ---

		function useEffectSetScroll(scrollCurrent: EventState): void {
			const { x: xCurrent, y: yCurrent } = scrollCurrent;
			const { x: xPrev, y: yPrev, dirX: dirXPrev, dirY: dirYPrev } = scroll;

			const dirXCurrent =
				xCurrent > xPrev ? directions.lateral.right : directions.lateral.left;
			const dirYCurrent =
				yCurrent > yPrev ? directions.vertical.down : directions.vertical.up;

			const state = Object.assign(
				scrollCurrent,
				dirXCurrent !== dirXPrev && { dirX: dirXCurrent },
				dirYCurrent !== dirYPrev && { dirY: dirYCurrent }
			);

			setScroll(state);
		}

		// --- Exports ---

		const state = scroll;

		const setters = {
			setScroll,
			setScrollDirectionX,
			setScrollDirectionY,
			setScrollTop
		};

		const effects = {
			useEffectSetScroll
		};

		//

		const actions = {
			...setters,
			...effects
		};

		return {
			scroll: { state, actions }
		};
	}
);

export const Provider = (props: React.PropsWithChildren<State>) => {
	const { children, ...state } = props;
	return <useScrollContext.Provider {...state}>{children}</useScrollContext.Provider>;
};
